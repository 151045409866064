@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --font-family: "Poppins", sans-serif;

  --backgroundColor: #ffffff;

  --mainColor: #21bdc5;
  --mainColor100: rgba(34, 189, 197, 0.1);
  --secondaryColor: #f9f9f9;

  --baseColor: #000000;
  --whiteColor: #ffffff;

  --firstColor: #6a6a6a;
  --secondColor: #c0c0c0;
  --thirdColor: #dcdcdc;
  --fourthColor: #fbfbfb;

  --redColor100: rgba(255, 0, 0, 0.1);
  --greenColor100: rgba(15, 231, 37, 0.1);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  color: var(--baseColor);
  background: var(--background);
  font-family: var(--font-family);
  overflow-x: hidden;
}

.action.active.add div,
.action.add:hover div {
  background-color: var(--mainColor100);
}

.action.active.remove div,
.action.remove:hover div {
  background-color: var(--redColor100);
}

.action.active.redeem div,
.action.redeem:hover div {
  background-color: var(--greenColor100);
}

.action.active.add img,
.action.add:hover img {
  filter: invert(45%) sepia(100%) saturate(361%) hue-rotate(134deg)
    brightness(97%) contrast(88%);
}

.action.active.remove img,
.action.remove:hover img {
  filter: brightness(0) saturate(100%) invert(75%) sepia(18%) saturate(3515%)
    hue-rotate(306deg) brightness(95%) contrast(114%);
}

.action.active.redeem img,
.action.redeem:hover img {
  filter: brightness(0) saturate(100%) invert(93%) sepia(58%) saturate(650%)
    hue-rotate(53deg) brightness(100%) contrast(91%);
}

.gridNumber.active {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.MuiFormControl-root label {
  color: var(--firstColor) !important;
  font-family: "Poppins", sans-serif !important;
}

.MuiFormControl-root input {
  font-family: "Poppins", sans-serif !important;
  color: var(--firstColor) !important;
}

.MuiFormControl-root label.Mui-focused {
  color: var(--mainColor) !important;
}

.MuiFormControl-root .MuiOutlinedInput-notchedOutline,
.MuiFormControl-root .MuiOutlinedInput-notchedOutline {
  border-color: var(--secondColor);
}

.MuiFormControl-root .Mui-focused .MuiOutlinedInput-notchedOutline,
.MuiFormControl-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--mainColor) !important;
}

.MuiStack-root {
  padding-top: 0 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px var(--whiteColor) inset !important;
  color: var(--firstColor);
  -webkit-text-fill-color: var(--firstColor);
}

select.minimal {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;
}
